import { BaseControlError } from "@components/form/BaseControlError"
import { useTranslations } from "@hooks/translation"
import { useBreakpoints } from "@hooks/utils"
import { getCode, getName } from "country-list"
import React, { useEffect } from "react"
import { Checkbox, DateControl, DatePicker, GeoCodingInput, Input, Nationality, PhoneNumberInput, useAssociationInformationState } from ".."
import { useCreationFormData } from "./hooks/useCreationFormData"
import { useParams } from "react-router-dom"

export const CreationDonatorForm = (props: Props) => {

    const [
        TITLE,
        CIVILTY,
        CIVILTY_MISTER,
        CIVILTY_MADAME,
        LASTNAME,
        FIRSTNAME,
        BIRTHDAY,
        PLACEOFBIRTH_LABEL,
        RECEIVEEMAIL_LABEL,
        _1,
        NATIONALITY,
        ADRESS,
        POSTAL_CODE,
        CITY,
        EMAIL,
        PHONE,
    ] = useTranslations(i18n)

    
	const { associationId } = useParams()
    const { md } = useBreakpoints()
    const { formData, handleInputChange, getError, errors, getInputProps, validateControl } = useCreationFormData()
    const [associationInfo] = useAssociationInformationState(associationId as string)

    useEffect(() => {
        if (formData.address?.address) {
            validateControl('address.city')
            validateControl('address.postal_code')
        }
    }, [formData.address])

    return (
        <div className="creation-form-container flex flex-col gap-7">
            <h4 className={`${md ? 'text-[20px]' : ''} font-bold`}>{TITLE}</h4>

            <div className="flex flex-col gap-7">
                {
                    !md &&
                    <>
                        <div className="gap-5 flex">
                            <div className="flex-[0.2]">
                                <Input
                                    label={CIVILTY}
                                    suggestions={[
                                        { label: 'M.', value: 'M.' },
                                        { label: 'Mme.', value: 'Mme.' },
                                    ]}
                                    suggestionOnFocus={true}
                                    {...getInputProps('title')}
                                    inputClassName="rounded"
                                    data-cy="home-page-donator-civility-input"
                                />
                            </div>
                            <div className="flex-[0.4]">
                                <Input
                                    label={LASTNAME}
                                    {...getInputProps('last_name')}
                                    data-cy="home-page-donator-lastName-input"
                                />
                            </div>
                            <div className="flex-[0.4]">
                                <Input
                                    label={FIRSTNAME}
                                    {...getInputProps('first_name')}
                                    data-cy="home-page-donator-firstName-input"
                                />
                            </div>
                        </div>
                        <div className="gap-4 flex">
                            <div className="flex-[0.2]">
                                <DatePicker
                                    label={BIRTHDAY}
                                    showYearDropdown
                                    scrollableYearDropdown
                                    yearDropdownItemNumber={100}
                                    {...getInputProps('dob')}
                                    error={undefined}
                                />
                            </div>
                            <div className="flex-[0.4]">
                                <Input
                                    label={PLACEOFBIRTH_LABEL}
                                    {...getInputProps('place_of_birth')}
                                    data-cy="home-page-donator-placeOfBirth-input"
                                />
                            </div>
                            <div className="flex-[0.4]">
                                <Nationality
                                    label={NATIONALITY}
                                    {...getInputProps('nationality')}
                                    data-cy="home-page-donator-nationality-input"
                                />
                            </div>
                        </div>
                        {
                            getError('dob') &&
                            <div>
                                <BaseControlError error={getError('dob') as string} />
                            </div>
                        }
                    </>
                }

                {/* Mobile displaying */}
                {
                    md &&
                    <>
                        <div className="flex gap-5">
                            <div className="flex-[0.3]">
                                <Input
                                    label={CIVILTY}
                                    suggestions={[
                                        { label: 'M.', value: 'M.' },
                                        { label: 'Mme.', value: 'Mme.' },
                                        { label: 'Mlle.', value: 'Mlle.' },
                                    ]}
                                    suggestionOnFocus={true}
                                    {...getInputProps('title')}
                                    data-cy="home-page-donator-civility-input"
                                />
                            </div>
                            <div className="flex-[0.7]">
                                <Input
                                    label={LASTNAME}
                                    {...getInputProps('last_name')}
                                    data-cy="home-page-donator-lastName-input"
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-7 md:hidden">
                            <div className="flex-1">
                                <Input
                                    label={FIRSTNAME}
                                    {...getInputProps('first_name')}
                                    data-cy="home-page-donator-firstName-input"
                                />
                            </div>
                            <div className="flex-1">
                                <div className="flex gap-5">
                                    <DatePicker
                                        label={BIRTHDAY}
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={100}
                                        {...getInputProps('dob')}
                                        error={undefined}
                                    />
                                    <Input
                                        label={PLACEOFBIRTH_LABEL}
                                        {...getInputProps('place_of_birth')}
                                        data-cy="home-page-donator-placeOfBirth-input"
                                    />
                                </div>
                            </div>
                            {
                                getError('dob') &&
                                <div>
                                    <BaseControlError error={getError('dob') as string} />
                                </div>
                            }
                        </div>

                        <div className="flex gap-5 ">
                            <Nationality
                                label={NATIONALITY}
                                {...getInputProps('nationality')}
                                data-cy="home-page-donator-nationality-input"
                            />
                        </div>
                    </>
                }



                {/* Mobile displaying end */}

                <div>
                    <GeoCodingInput
                        label={ADRESS}
                        {...getInputProps('address.address')}
                        name={undefined}
                        onResult={(result) => {
                            handleInputChange('address.postal_code', result?.zipCode)
                            handleInputChange('address.city', result?.city)
                        }}
                        data-cy="home-page-donator-address-input"
                    />
                </div>

                <div className="flex gap-4">
                    <div className="flex-[0.4]">
                        <Input
                            label={POSTAL_CODE}
                            name={undefined}
                            value={formData?.address?.postal_code || ''}
                            error={getError('address.postal_code') as string}
                            data-cy="home-page-donator-postalCode-input"
                        />
                    </div>
                    <div className="flex-[0.6]">
                        <Input
                            label={CITY}
                            name={undefined}
                            value={formData?.address?.city || ''}
                            error={getError('address.city') as string}
                            data-cy="home-page-donator-city-input"
                        />
                    </div>
                </div>

                <div>
                    <Input
                        label={EMAIL}
                        {...getInputProps('email')}
                        data-cy="home-page-donator-email-input"
                    />
                </div>

                <div>
                    <PhoneNumberInput
                        label={PHONE}
                        {...getInputProps('phone')}
                        id="phone"
                        onChange={(value, countryCode) => {
                            handleInputChange('phone', value)
                            handleInputChange('country_code', countryCode)
                        }}
                    />
                </div>

                <div>
                    <Checkbox
                        options={[
                            { label: RECEIVEEMAIL_LABEL.replace('$1', associationInfo?.first_name!), value: true }
                        ]}
                        value={formData.receive_email}
                        onChange={(value) => handleInputChange('receive_email', value === true)}
                        data-cy="home-page-donator-acceptReceivingEmail-checkbox"
                    />
                </div>
            </div>
        </div>
    )
}

type Props = {
    
}

const i18n = [
    'Vadato.Donators.Create.Form.Title',
    'Vadato.Donators.Create.Form.Civilty.Label',
    'Vadato.Donators.Create.Form.Civilty.Mister.Label',
    'Vadato.Donators.Create.Form.Civilty.Madame.Label',
    'Vadato.Donators.Create.Form.LastName.Label',
    'Vadato.Donators.Create.Form.FirstName.Label',
    'Vadato.Donators.Create.Form.BirthDay.Label',
    'Vadato.Donators.Create.Form.PlaceOfBirth.Label',
    "Vadato.Donators.Create.Form.ReceiveEmail.Label",
    'Vadato.Donators.Create.Form.BirthDay.Validation.Error',
    'Vadato.Donators.Create.Form.Nationality.Label',
    'Vadato.Donators.Create.Form.Adress.Label',
    'Vadato.Donators.Create.Form.PostalCode.Label',
    'Vadato.Donators.Create.Form.City.Label',
    'Vadato.Donators.Create.Form.Email.Label',
    'Vadato.Donators.Create.Form.Phone.Label',
]
