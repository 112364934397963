import { useTranslations } from "@hooks/translation"
import { useUrlValidation } from "@hooks/url"
import { useBreakpoints } from "@hooks/utils"
import _ from "lodash"
import React, { useMemo } from "react"
import { useState } from "react"
import { Button, useAssociationInformationState } from ".."
import { useAssociationColorValue } from "./hooks/useAssociationColorValue"
import { useParams } from "react-router-dom"

export const AssociationCreationDescription = (props: DonatorCreationDescriptionProps) => {

    const [
        CREATE_TITLE,
        CREATE_SUBTITLE,
        DESCRIPTION_TITLE,
        DESCRIPTION_CONTENT1,
        DESCRIPTION_CONTENT2,
        DESCRIPTION_WEBSITE,
        DESCRIPTION_WEBSITE_VALUE,
        CONTINUE
    ] = useTranslations(i18n)

    const { sm, md } = useBreakpoints()
    const [isMinimized, setIsMinimized] = useState(props.minimized)
    const { associationId } = useParams()
    const [associationInfo] = useAssociationInformationState(associationId)
    const isValidUrl = useUrlValidation()

    const {
		backgroundColor,
		borderColor,
		buttonColor,
		buttonTextColor,
		nameColor,
		titleColor
	} = useAssociationColorValue(associationInfo.color_code)

    const siteUrl = useMemo(() => {
        return isValidUrl(associationInfo.website) ? `${associationInfo.website}` : `https://${associationInfo.website}`
    }, [associationInfo, props])

    return (
        <div className={`
            bg-white w-full
            ${sm ? 'absolute top-0 left-0 right-0  z-50' : 'relative inset-[unset]' }
            ${isMinimized ? 'h-[90px] rounded-b-[40px] shadow-lg pt-2' : `${sm ? 'h-screen' : 'h-auto'}`}
            overflow-auto overflow-x-hidden md:overflow-visible
            transition-all
            flex flex-col
        `}
            style={{ transitionDuration: '500ms', backgroundColor: isMinimized ? backgroundColor : undefined }}
        >
            {
                !isMinimized &&
                <img
                    src={associationInfo.bg_img}
                    className="w-full min-h-[200px]"
                />
            }

            <div 
                className={`${!isMinimized ? "py-5 px-7 md:px-12 md:py-20 flex-1" : "px-3 flex justify-center items-center"}`}
                style={{ backgroundColor }}>
                <div className="flex gap-4" onClick={() => setIsMinimized(false)}>
                    <img src={associationInfo.logo} className="w-[48px] md:w-[96px] object-contain" />
                    <div className="flex flex-col justify-center">
                        <h2 className={`${ md ? 'text-[27px] ': ''} overflow-hidden text-ellipsis`} title={associationInfo.first_name} style={{ color: nameColor }}>{associationInfo.first_name}</h2>
                        <h3 className={`${ md ? 'text-[20px] ': ''} overflow-hidden text-ellipsis`} style={{ color: titleColor }}>{associationInfo.title}</h3>
                    </div>
                </div>
                {
                    !isMinimized &&
                    <>
                        <div className="mt-10 flex flex-col gap-5">
                            <div dangerouslySetInnerHTML={{ __html: associationInfo.description || '' }} />
                            {associationInfo.website && (
                                <p className="mt-5 text-right underline text-[12px] md:text-[16px]">
                                    {
                                        props.isPreviewMode ?
                                        <><span>{DESCRIPTION_WEBSITE}</span> <span className="cursor-pointer">{associationInfo.website}</span></> :
                                        <><span>{DESCRIPTION_WEBSITE}</span> <a href={siteUrl} target="_blank">{associationInfo.website}</a></>
                                    }
                                </p>
                            )}
                        </div>
                        <div className={`${props.forceShowButton !== true ? 'md:hidden' : ''} mt-10 mb-5`}>
                            <Button 
                                className="btn-primary w-full flex text-center" 
                                style={{ backgroundColor: buttonColor, color: buttonTextColor, justifyContent: 'center' }}
                                onClick={() => {
                                    if(!props.isPreviewMode){
                                        setIsMinimized(true)
                                    }
                                }}>
                                {CONTINUE}
                            </Button>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

const i18n = [
    "Vadato.Donators.Create.Title",
    "Vadato.Donators.Create.Subtitle",
    "Vadato.Donators.Create.Description.Title",
    "Vadato.Donators.Create.Description.Content1",
    "Vadato.Donators.Create.Description.Content2",
    "Vadato.Donators.Create.Description.Website",
    "Vadato.Donators.Create.Description.Website.Value",
    "Vadato.Donators.Create.Continue.Button.Label",
]

export type DonatorCreationDescriptionProps = {
    minimized: boolean
    forceShowButton?: boolean
    onContinueClick?: () => void
    isPreviewMode?: boolean
}
